import { graphql, useStaticQuery } from 'gatsby'

const useNavigation = () => {
  const data = useStaticQuery(graphql`
    query {
      footer: sanityFooter {
        text
        footerItems {
          _key
          external
          link
          text
        }
        links {
          _key
          external
          link
          text
        }
        address
        phone
      }
    }
  `)

  const { footer } = data || {}
  return footer
}

export default useNavigation
