import { graphql, useStaticQuery } from 'gatsby'

const useNavigation = () => {
  const data = useStaticQuery(graphql`
    query {
      newsletter: sanityNewsletter {
        list
        placeholder
      }
    }
  `)

  const { newsletter } = data || {}
  return newsletter
}

export default useNavigation
