import astrochimp from 'astrochimp'
import React, { useState } from 'react'
import useNewsletter from '../hooks/use-newsletter'
import styled from 'styled-components'

const EmailWrapper = styled.div`
  form {
    display: flex;
    flex-direction: row;
    border-bottom: 1px dashed var(--white);
  }
  input::placeholder {
    color: var(--white);
  }
  button {
    font-size: 1.125rem;
  }
  color: var(--white);
`
const EmailInput = ({ onSuccess }) => {
  const newsletter = useNewsletter()

  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    const url = newsletter.list
    const data = { EMAIL: email }

    astrochimp(url, data, err => {
      if (err) {
        console.log(err)
        if (err.msg.indexOf('already subscribed') >= 0) {
          setStatus('*Already Subscribed')
          if (onSuccess) onSuccess()
        } else {
          setStatus('*Invalid Address')
        }
      } else {
        setEmail('Thank you!')
        setStatus('')
        if (onSuccess) onSuccess()
      }
    })
  }

  const handleInputChange = e => {
    setEmail(e.target.value)
  }
  return (
    <EmailWrapper>
      <span>{status}</span>
      <form className="inline" onSubmit={handleSubmit}>
        <input
          type="email"
          required={true}
          value={email}
          onChange={handleInputChange}
          placeholder="your e-mail"
          className="uppercase focus:outline-none bg-transparent"
        />
        <button className="focus:outline-none" type="submit">
          &#8594;
        </button>
      </form>
    </EmailWrapper>
  )
}

export default EmailInput
